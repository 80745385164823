module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Commune de Lumes","short_name":"Lumes","start_url":"/","background_color":"#ABBD25","theme_color":"#ABBD25","display":"standalone","icon":"src/images/maskable_icon.png","icon_options":{"purpose":"any"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9e4d11c5f2cebfc5c775191d7e3c8fe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Accueil","crumbLabelUpdates":[{"pathname":"/actualites","crumbLabel":"Actualités"},{"pathname":"/agenda","crumbLabel":"Agenda"},{"pathname":"/contacts","crumbLabel":"Annuaire"},{"pathname":"/vivre-lumes","crumbLabel":"Vivre à Lumes"},{"pathname":"/la-mairie","crumbLabel":"La mairie"},{"pathname":"/cadre-de-vie","crumbLabel":"Cadre de vie"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"external"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":3}}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
